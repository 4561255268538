import { CONFIRMATION_REQUIRED } from 'Component/MyAccountCreateAccount/MyAccountCreateAccount.config';
import MyAccountQuery from 'Query/MyAccount.query';
import {
    CartDispatcher,
    CUSTOMER,
    MyAccountDispatcher as SourceMyAccountDispatcher,
    ONE_MONTH_IN_SECONDS,
    ProductCompareDispatcher,
    WishlistDispatcher,
} from 'SourceStore/MyAccount/MyAccount.dispatcher';
import { resetContractorData } from 'Store/Contractor/Contractor.actions';
import ContractorDispatcher from 'Store/Contractor/Contractor.dispatcher';
import {
    updateCustomerDetails,
    updateCustomerSignInStatus,
    updateIsLoading,
    updateIsLocked,
} from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { getAuthorizationToken, GRAPHQL_AUTH, isSignedIn, setAuthorizationToken } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getGuestQuoteId, setGuestQuoteId } from 'Util/Cart';
import { history } from 'Util/History';
import { fetchMutation, fetchQuery, getErrorMessage } from 'Util/Request';
import getStore from 'Util/Store';

export const CONTRACTOR = 'contractor';

export * from 'SourceStore/MyAccount/MyAccount.dispatcher';

/**
 * My account actions
 * @class MyAccount
 * @namespace Pwabb/Store/MyAccount/Dispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    handleCustomerDataOnInit(dispatch) {
        if (isSignedIn()) {
            return this.requestCustomerData(dispatch);
        }

        BrowserDatabase.deleteItem(CUSTOMER);
        CartDispatcher.then(({ default: dispatcher }) => dispatcher.resetGuestCart(dispatch));
    }

    requestCustomerData(dispatch) {
        return fetchQuery(MyAccountQuery.getCustomerQuery()).then(
            /** @namespace Pwabb/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/fetchQuery/then */
            ({ customer }) => {
                if (!getAuthorizationToken()) {
                    return;
                }

                dispatch(updateIsLocked(false));
                dispatch(updateCustomerDetails(customer));
                BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS);

                return ContractorDispatcher.prepareRequest(dispatch);
            },
            /** @namespace Pwabb/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/fetchQuery/then/catch */
            (error) => {
                const {
                    extensions: { category },
                } = error[0];

                if (category === GRAPHQL_AUTH) {
                    dispatch(updateIsLocked(true));
                }
                dispatch(showNotification('error', getErrorMessage(error)));
            }
        );
    }

    /**
     * Create account action
     * @param {{customer: Object, password: String}} [options={}]
     * @memberof MyAccountDispatcher
     */
    createAccount(options = {}, dispatch) {
        const mutation = MyAccountQuery.getCreateAccountMutation(options);
        dispatch(updateIsLoading(true));

        return fetchMutation(mutation).then(
            /** @namespace Pwabb/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/fetchMutation/then */
            (data) => {
                const {
                    createCustomer: { customer },
                } = data;
                const { confirmation_required } = customer;

                if (confirmation_required) {
                    dispatch(updateIsLoading(false));

                    return CONFIRMATION_REQUIRED;
                }

                setTimeout(() => {
                    history.push('/');
                }, 2000);
                // return this.signIn({ email }, dispatch);
            },

            /** @namespace Pwabb/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/fetchMutation/then/catch */
            (error) => {
                dispatch(updateIsLoading(false));
                dispatch(showNotification('error', getErrorMessage(error)));
                return false;
            }
        );
    }

    async signIn(options = {}, dispatch) {
        const mutation = MyAccountQuery.getSignInMutation(options);
        const result = await fetchMutation(mutation);
        const {
            generateCustomerToken: { token },
        } = result;
        const store = getStore();
        const { ConfigReducer: { code = '' } = {} } = store.getState();
        BrowserDatabase.setItem(code, 'customerStoreCode');

        setAuthorizationToken(token);

        ProductCompareDispatcher.then(({ default: dispatcher }) => dispatcher.assignCompareList(dispatch));

        const cartDispatcher = (await CartDispatcher).default;
        const guestCartToken = getGuestQuoteId();
        const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

        if (guestCartToken && guestCartToken !== customerCartToken) {
            await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
        }

        setGuestQuoteId(customerCartToken);
        cartDispatcher.updateInitialCartData(dispatch, true);

        WishlistDispatcher.then(({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch));

        await this.requestCustomerData(dispatch);
        dispatch(updateCustomerSignInStatus(true));
        dispatch(updateIsLoading(false));
        dispatch(hideActiveOverlay());
        dispatch(showNotification('success', __('You are successfully logged in!')));

        return true;
    }

    logout(authTokenExpired = false, isWithNotification = true, dispatch) {
        super.logout(authTokenExpired, isWithNotification, dispatch);
        dispatch(resetContractorData());
    }
}

export default new MyAccountDispatcher();
