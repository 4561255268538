import { FieldSelect as AdvoxFieldSelect } from 'AdvoxComponent/FieldSelect/FieldSelect.component';
import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import Icons from 'Component/Icons';
import ClickOutside from 'SourceComponent/ClickOutside';

import './FieldSelect.override.style';
/** @namespace Pwabb/Component/FieldSelect/Component */
export class FieldSelect extends AdvoxFieldSelect {
    renderOption(option) {
        const { id, label, subLabel, isHovered, value } = option;

        const {
            isExpanded,
            handleSelectListOptionClick,
            attr: { value: selectedValue },
        } = this.props;

        return (
            <li
                block="FieldSelect"
                elem="Option"
                mods={{ isExpanded, isHovered, isSelected: selectedValue === value }}
                key={id}
                id={`o${id}`}
                role="menuitem"
                onMouseDown={() => handleSelectListOptionClick(option)}
                onTouchStart={() => handleSelectListOptionClick(option)}
                onKeyPress={() => handleSelectListOptionClick(option)}
                tabIndex={isExpanded ? '0' : '-1'}
            >
                {label}
                {subLabel && <strong>{` ${subLabel}`}</strong>}
            </li>
        );
    }

    renderNativeOption(option) {
        const { id, value, disabled, label, subLabel = '', isAvailable = true } = option;

        const { isDisabled, attr: { defaultValue = '' } = {} } = this.props;

        if (defaultValue) {
            if (option?.name === defaultValue) {
                return (
                    <option key={id} id={id} value={value} disabled={disabled || isDisabled || !isAvailable}>
                        {option?.label}
                    </option>
                );
            }

            return;
        }

        return (
            <option key={id} id={id} value={value} disabled={disabled || isDisabled || !isAvailable}>
                {`${label} ${subLabel}`}
            </option>
        );
    }

    render() {
        const {
            attr: { id = '' } = {},
            isExpanded,
            isDisabled,
            handleSelectExpand,
            handleSelectListKeyPress,
            handleSelectExpandedExpand,
            isChevronIcon,
        } = this.props;

        return (
            <ClickOutside onClick={handleSelectExpandedExpand}>
                <div
                    id={`${id}_wrapper`}
                    block="FieldSelect"
                    mods={{ isExpanded }}
                    onClick={!isDisabled ? handleSelectExpand : null}
                    onKeyPress={handleSelectListKeyPress}
                    role="button"
                    tabIndex="0"
                    aria-label="Select dropdown"
                    aria-expanded={isExpanded}
                >
                    <div block="FieldSelect" elem="Clickable">
                        {this.renderNativeSelect()}
                        {isChevronIcon ? (
                            <ChevronIcon direction={isExpanded ? TOP : BOTTOM} />
                        ) : (
                            <div block="FieldSelect" elem="Arrow" mods={{ isExpanded, isDisabled }}>
                                <Icons name="dropdown_arrow" width="20" />
                            </div>
                        )}
                    </div>
                    {this.renderOptions()}
                </div>
            </ClickOutside>
        );
    }
}

export default FieldSelect;
