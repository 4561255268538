import { FieldSelectContainer as SourceFieldSelectContainer } from 'AdvoxComponent/FieldSelect/FieldSelect.container';

/**
 * Field Select
 * @class FieldSelectContainer
 * @namespace Pwabb/Component/FieldSelect/Container */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    isSelectedOptionAvailable() {
        const options = this.getOptions();
        const selectedOptionIndex = this.fieldRef.options.selectedIndex;
        const selectedOption = options[selectedOptionIndex];

        const isAvailable = selectedOption && selectedOption.isAvailable !== false;

        this.setState({
            selectedOptionIndex,
            isSelectedOptionAvailable: isAvailable,
        });
    }

    containerProps() {
        const { isChevronIcon } = this.props;

        return {
            ...super.containerProps(),
            isChevronIcon,
        };
    }
}

export default FieldSelectContainer;
