import { getAuthorizationToken } from 'SourceUtil/Auth/Token';
import { updateCustomerSignInStatus } from 'Store/MyAccount/MyAccount.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteGuestQuoteId } from 'Util/Cart';
import { removeUid } from 'Util/Compare';
import getStore from 'Util/Store';

export * from 'SourceUtil/Auth/Token';

/** @namespace Pwabb/Util/Auth/Token/isSignedIn */
export const isSignedIn = () => {
    const _isSignedIn = !!getAuthorizationToken();
    const store = getStore();
    const { MyAccountReducer: { isSignedIn: isCustomerSignedIn } = {} } = store.getState();
    const { dispatch } = store;
    const { ConfigReducer: { code = '' } = {} } = store.getState();

    const customerStoreCode = BrowserDatabase.getItem('customerStoreCode') || code;
    if ((!_isSignedIn && isCustomerSignedIn) || code !== customerStoreCode) {
        deleteGuestQuoteId();
        dispatch(updateCustomerSignInStatus(false));
        removeUid();

        const MyAccountDispatcher = import('../../store/MyAccount/MyAccount.dispatcher');
        if (code !== customerStoreCode) {
            MyAccountDispatcher.then(({ default: dispatcher }) => dispatcher.logout(false, true, dispatch));
            BrowserDatabase.setItem(code, 'customerStoreCode');
        } else {
            MyAccountDispatcher.then(({ default: dispatcher }) => dispatcher.logout(true, true, dispatch));
        }
    }

    return _isSignedIn;
};
