import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FieldContainer as SourceFieldContainer } from 'AdvoxComponent/Field/Field.container';

/** @namespace Pwabb/Component/Field/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isSignedIn: state.MyAccountReducer.isSignedIn,
});

/** @namespace Pwabb/Component/Field/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwabb/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    static propTypes = {
        ...SourceFieldContainer.propTypes,
        hidden: PropTypes.bool,
    };

    static defaultProps = {
        ...SourceFieldContainer.defaultProps,
        hidden: false,
    };

    containerProps() {
        const { hidden, isSignedIn, isDisabled, isHalfWidth, isChevronIcon } = this.props;

        return {
            ...super.containerProps(),
            isChevronIcon,
            hidden,
            isSignedIn,
            isDisabled,
            isHalfWidth,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldContainer);
