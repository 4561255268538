import { PatternFormat } from 'react-number-format';

import { FieldComponent as AdvoxFieldComponent } from 'AdvoxComponent/Field/Field.component';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import FieldDatePicker from 'Component/FieldDatePicker';
import FieldSelectContainer from 'Component/FieldSelect/FieldSelect.container';
import Icons from 'Component/Icons';

/** @namespace Pwabb/Component/Field/Component */
export class FieldComponent extends AdvoxFieldComponent {
    renderMap = {
        ...this.renderMap,
        [FIELD_TYPE.tel]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.date]: this.renderDateInput.bind(this),
        [FIELD_TYPE.dateRange]: this.renderDateInput.bind(this),
    };

    renderErrorIcon = () => <Icons name="error" width="22" height="22" />;

    renderSuccessIcon = () => <Icons name="check_ok" width="22" height="22" />;

    renderDateInput() {
        const { type, attr, events, setRef, label, isDisabled = false } = this.props;

        return (
            <FieldDatePicker
                attr={attr}
                label={label}
                selectsRange={type === FIELD_TYPE.dateRange}
                events={events}
                setRef={setRef}
                isDisabled={isDisabled}
            />
        );
    }

    renderDefaultInput() {
        const { type, setRef, attr, attr: { format, mask = '_' } = {}, events, isDisabled } = this.props;

        if (format) {
            return (
                <PatternFormat
                    {...attr}
                    {...events}
                    format={format}
                    mask={mask}
                    displayType="input"
                    getInputRef={(elem) => setRef(elem)}
                    disabled={isDisabled}
                    type={type}
                />
            );
        }

        return <input ref={(elem) => setRef(elem)} disabled={isDisabled} type={type} {...attr} {...events} />;
    }

    renderSelect() {
        const {
            attr,
            events,
            setRef,
            options,
            isDisabled = false,
            changeValueOnDoubleClick,
            isSortSelect,
            isChevronIcon,
        } = this.props;

        return (
            <FieldSelectContainer
                attr={attr}
                events={events}
                options={options}
                setRef={setRef}
                isDisabled={isDisabled}
                isSortSelect={isSortSelect}
                isChevronIcon={isChevronIcon}
                changeValueOnDoubleClick={changeValueOnDoubleClick}
            />
        );
    }

    render() {
        const { type, validationResponse, mix, variant, size, isHalfWidth } = this.props;
        const inputRenderer = this.renderMap[type];

        const isValid = validationResponse === true;
        const hasError = validationResponse !== true && Object.keys(validationResponse || {}).length !== 0;
        const renderValidationIcon = [
            FIELD_TYPE.email,
            FIELD_TYPE.text,
            FIELD_TYPE.password,
            FIELD_TYPE.textarea,
            FIELD_TYPE.tel,
        ].includes(type);

        return (
            <div block="Field" elem="Wrapper" mods={{ type, isHalfWidth }}>
                <div
                    block="Field"
                    mods={{
                        type,
                        hasError,
                        variant,
                        size,
                    }}
                    mix={mix}
                >
                    {type !== FIELD_TYPE.checkbox && type !== FIELD_TYPE.radio && this.renderLabel()}
                    <div block="Field" elem="InputWrapper">
                        {inputRenderer && inputRenderer()}
                        {hasError && renderValidationIcon && this.renderErrorIcon()}
                        {isValid && renderValidationIcon && this.renderSuccessIcon()}
                    </div>
                    {this.renderErrorMessages()}
                </div>

                {this.renderSubLabel()}
            </div>
        );
    }
}

export default FieldComponent;
